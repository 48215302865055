import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Section, Columns } from 'react-bulma-components'

// Components
import ArrowLink from 'components/link-with-arrow'
import DownloadArticle from 'components/download-article'

function AboutAuthor({ data }) {
  return (
    <div className="">
      <Columns className="is-vcentered">
        <Columns.Column className="is-2 is-4-mobile is-one-fifth-tablet">
          {data.page.author.mainImage ? (
            <GatsbyImage
              image={data.page.author.mainImage.gatsbyImageData}
              alt={data.page.author.mainImage.alt || 'Pocketworks'}
              className="mr-0 is-outlined"
            />
          ) : (
            <br />
          )}
        </Columns.Column>
        <Columns.Column className="is-size-10-mobile is-10-desktop is-four-fifths-tablet is-12-mobile">
          <div className="content">
            <p className="is-size-6 is-size-6-mobile mb-2">
              By{' '}
              <Link to={`/about/pocketeers/${data.page.author.slug}`}>
                {data.page.author.name}
              </Link>
              <br />
              <span className="has-text-grey">
                {data.page.author.role}, Pocketworks
              </span>
              <br />
              <span className="has-text-grey">
                {data.page.meta.firstPublishedAt}
                {data.page.meta.firstPublishedAt !==
                data.page.meta.PublishedAt ? (
                  <>
                    <br />
                    <i>Updated {data.page.meta.publishedAt}</i>
                  </>
                ) : (
                  <span />
                )}
              </span>
            </p>
          </div>
        </Columns.Column>
      </Columns>
    </div>
  )
}

AboutAuthor.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutAuthor
