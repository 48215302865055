import React from 'react'
import { Link } from 'gatsby'

function AboutPocketworks() {
  return (
    <>
      <h2>Making apps that make a difference</h2>
      <p>
        In case you're wondering, Pocketworks is a software consultancy that
        specialises in mobile apps.
      </p>
      <p>
        We bring you expertise in user research, mobile technology and app
        growth tactics to help you develop apps that create positive impact for
        your customers, shareholders and society.
      </p>
      <p>
        To get a flavour of us, check out our&nbsp;
        <Link to="/mobile-strategy-toolkit">free guides</Link> and{' '}
        <Link to="/services">app development services</Link>. Or,&nbsp;
        <Link to="/about">see some more background info on us</Link>.
      </p>
    </>
  )
}

export default AboutPocketworks
