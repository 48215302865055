import PropTypes from 'prop-types'
import React, { useRef } from 'react'

function DownloadArticle({ slug }) {
  const formRef = useRef(null)

  const toggleForm = () => {
    formRef.current.classList.toggle('is-active')
  }

  const handleSubmit = (e) => {
    console.log('Submitting')
    e.preventDefault()
    const myForm = document.getElementById('download-blog')
    const formData = new FormData(myForm)
    // document.querySelector('#form-submit-button').innerText =
    //   'Please wait a few seconds...'
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        console.log('Form successfully submitted')
        // document.querySelector('#form-submit-button').innerText =
        //   'Please wait a few seconds...'
        const pdfUrl = document.querySelector('#article-pdf').value
        window.open(pdfUrl, '_blank').focus()
        toggleForm()
      })
      .catch((error) => alert(error))
  }

  return (
    <div className="no-print">
      <a
        className="button ml-2 is-size-6 is-leafy is-medium is-primary mb-3"
        id="download-pdf-link"
        onClick={toggleForm}
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
      >
        <i className="fa-solid fa-file-pdf" />
        &nbsp; Download article as a PDF
      </a>

      <div className="modal" id="modal-download-box" ref={formRef}>
        <div className="modal-background" />
        <div className="modal-card">
          <form
            name="download-blog"
            id="download-blog"
            // method="POST"
            onSubmit={handleSubmit}
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="download-blog" />
            <input
              type="hidden"
              name="article"
              value={`https://pocketworks.co.uk/blog/${slug}`}
            />
            <input
              type="hidden"
              id="article-pdf"
              name="article-pdf"
              value={`https://api.html2pdf.app/v1/generate?url=https://pocketworks.co.uk/blog/${slug}&apiKey=XEJIrYSTgaTp4zPsv9eMK6Wm2IzCVkmilhsSeSxlP8D4TlUnX2Q4tYhGm04qCWHv&media=print&scale=0.8`}
            />
            <header className="modal-card-head">
              <p className="modal-card-title">Download</p>
            </header>
            <section className="modal-card-body">
              <div className="field">
                <label className="label">Your name*</label>
                <div className="control">
                  <input
                    className="input is-success"
                    type="text"
                    name="name"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Your email*</label>
                <div className="control">
                  <input
                    className="input is-success"
                    type="email"
                    name="email"
                    required
                  />
                </div>
              </div>
              <p>
                <span class="is-hidden has-text-primary is-font-weight-bold is-size-4">
                  *&nbsp;
                </span>
                <span class="box">
                  You will receive occasional emails when we post more awesome
                  content like this. You can unsubscribe at any time if you
                  don't like it.
                </span>
              </p>
              <p>
                <b>Note: Please wait a few seconds after hitting the button.</b>
              </p>
            </section>
            <footer className="modal-card-foot">
              <button
                className="button is-success"
                id="form-submit-button"
                type="submit"
              >
                Download now
              </button>
              <button
                type="button"
                className="button cancel-button"
                onClick={toggleForm}
              >
                Cancel
              </button>
            </footer>
          </form>
        </div>
      </div>
    </div>
  )
}

DownloadArticle.propTypes = {
  slug: PropTypes.string,
}

export default DownloadArticle
