import PropTypes from 'prop-types'
import React from 'react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

function AuthorImage({ data }) {
  return (
    <>
      {data.page.featuredMedia ? (
        <div className="pb-6">
          <GatsbyImage
            image={data.page.featuredMedia.gatsbyImageData}
            alt={data.page.title || 'Pocketworks'}
            className="has-shadow-top-bottom w-full"
          />
        </div>
      ) : (
        <span />
      )}
      <div className="side-plant is-hidden-mobile">
        <StaticImage
          src="https://www.datocms-assets.com/20955/1579120450-plant-motif-left.png"
          alt="Plant Motif Leaf"
        />
      </div>
    </>
  )
}
AuthorImage.propTypes = {
  data: PropTypes.object,
}
export default AuthorImage
