import React from 'react'

// Compoennts
import StrategySubscribeComponent from 'components/strategy-subscribe'
import { StaticImage } from 'gatsby-plugin-image'

function GrowKnowledge() {
  return (
    <div className="box has-background-white-ter pb-5 mb-6 is-size-6">
      <h4 className="title is-size-3 pb-0 mb-0">Subscribe to the newsletter</h4>
      <p className="pt-2 mb-0">
        Be the first to read our articles and get fortnightly tips on app
        research, development and growth.
      </p>
      <StaticImage
        src="https://www.datocms-assets.com/20955/1701526172-arrow-1.png?w=100"
        id="arrow-1"
        alt="Arrow"
        className="bust-out"
      />
      <StrategySubscribeComponent />
    </div>
  )
}

export default GrowKnowledge
