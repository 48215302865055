import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { Columns } from 'react-bulma-components'

function AlsoLike({ fallbackArticles, relatedArticles }) {
  if (relatedArticles.length !== 0) {
    return (
      <>
        <h3>You may also like...</h3>
        <Columns>
          {relatedArticles.map((article, index) => (
            <Columns.Column
              className="is-12 is-10-tablet is-multiline"
              key={index}
            >
              <Link to={`/blog/${article.slug}`}>{article.title}</Link>
            </Columns.Column>
          ))}
        </Columns>
      </>
    )
  }

  return (
    <>
      <h3>You may also like...</h3>
      <Columns>
        {fallbackArticles.articles.edges.map(({ node: article }, index) => (
          <Columns.Column
            className="is-12 is-10-tablet is-multiline"
            key={index}
          >
            <Link to={`/blog/${article.slug}`}>{article.title}</Link>
          </Columns.Column>
        ))}
      </Columns>
    </>
  )
}

AlsoLike.propTypes = {
  fallbackArticles: PropTypes.object.isRequired,
  relatedArticles: PropTypes.array,
}

export default AlsoLike
