import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { Section, Container, Columns } from 'react-bulma-components'
import { ArticleJsonLd } from 'gatsby-plugin-next-seo'
import AboutAuthor from '../about-author'

function AuthorHead({ data }) {
  return (
    <Section>
      <Container>
        <Columns>
          <Columns.Column className="is-10 content">
            <h2 className="title is-size-4 mb-0">
              <Link
                className="has-text-dark arrow-link-transform is-uppercase title is-size-4"
                to={`/blog/categories/${data.page.categories[0].slug}`}
              >
                <i className="fa-solid fa-arrow-left-long" />{' '}
                {data.page.categories[0].name}
              </Link>
            </h2>
            <h1 className="title is-size-3-mobile mt-2 has-text-dark">
              {data.page.title}
            </h1>
            <ArticleJsonLd
              headline={data.page.title}
              datePublished={new Date(data.page.meta.firstPublishedAt).toJSON()}
              dateModified={new Date(data.page.meta.PublishedAt).toJSON()}
              authorName={data.page.author.name}
              images={[
                `${
                  data.page.featuredMedia ? data.page.featuredMedia.url : null
                }&fm=jpg&w=1000`,
              ]}
              publisherName="Pocketworks Mobile Ltd"
              publisherLogo="https://www.datocms-assets.com/20955/1579431570-secondary-logo-small-square-v3a.png"
              description={data.page.excerpt}
            />
            <AboutAuthor data={data} />
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}
AuthorHead.propTypes = {
  data: PropTypes.object,
}
export default AuthorHead
